import { useEffect, useState } from "react";
import { IStockDB } from "../shared/IStockDB";
import { ISupplierDB } from "../shared/ISupplierDB";
import Data from "../utils/Data";

interface ISupplierWithStock {
  stock: IStockDB[];
  supplier: ISupplierDB;
}

export default (id: number) => {
  const [supplierWithStock, setSupplierWithStock] = useState<ISupplierWithStock>();

  useEffect(() => {
    Data.fetchSupplier(id).then(supplier => {
      Data.fetchStockBySupplier(supplier).then(stock => {
        setSupplierWithStock({ stock, supplier });
      });
    });
  }, []);

  return supplierWithStock;
};
