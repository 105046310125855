import { IUpdateSupplierForm } from "../shared/forms/UpdateSupplierForm";
import { IAttributeForCategory } from "../shared/IAttributeForCategory";
import { IAttributeMappingDB, IAttributesNotMapped } from "../shared/IAttributeMapping";
import { IAttributeValue } from "../shared/IAttributeValue";
import { IBrand } from "../shared/IBrand";
import { ICategoriesFilter } from "../shared/ICategoriesFilter";
import { ICategory } from "../shared/ICategory";
import { ICategoryMappingDB } from "../shared/ICategoryMappingDB";
import { ISkuRich } from "../shared/ISku";
import { IStockDB } from "../shared/IStockDB";
import { ISupplierDB } from "../shared/ISupplierDB";
import Http from "./Http";

export default {
  // Attributes
  attributes: {
    getForCategory: (id: number): Promise<IAttributeForCategory[]> =>
      Http.get<IAttributeForCategory[]>(`attributes/category/${id}`),

    getValues: (marketplace: string, attributeId: number): Promise<IAttributeValue[]> =>
      Http.get<IAttributeValue[]>(`attributes-values/${marketplace}/${attributeId}`)
  },

  // Attributes mappings
  attributesMappings: {
    getAll: (): Promise<IAttributeMappingDB[]> => Http.get<IAttributeMappingDB[]>(`attributes-mappings`),

    getNotMapped: (): Promise<IAttributesNotMapped[]> => Http.get<IAttributesNotMapped[]>(`attributes-not-mapped`),

    getByCategory: (category: ICategoryMappingDB): Promise<IAttributeMappingDB> =>
      Http.get<IAttributeMappingDB>(`attributes/category/${category.id}`)
  },

  // Brands
  fetchBrands: (): Promise<IBrand[]> => Http.get<IBrand[]>("brands"),

  // Categories
  fetchCategories: (marketplace: string, filter: ICategoriesFilter): Promise<ICategory[]> =>
    Http.get<ICategory[]>(`categories/${marketplace}/${filter}`),

  fetchCategoriesMappings: (): Promise<ICategoryMappingDB[]> => Http.get<ICategoryMappingDB[]>(`categories-mappings`),

  fetchCategory: (id: number): Promise<ICategoryMappingDB> => Http.get<ICategoryMappingDB>(`categories/${id}`),

  fetchProduct: (id: number): Promise<ISkuRich[]> => Http.get<ISkuRich[]>(`products/${id}`),

  fetchSKU: (id: number): Promise<ISkuRich> => Http.get<ISkuRich>(`skus/${id}`),

  // const fetchStockBySKU : (sku: ISkuDB): Promise<IStockDB[]> => Http.get<IStockDB[]>(`stock/sku/${sku.id}`),
  fetchStockBySupplier: (supplier: ISupplierDB): Promise<IStockDB[]> =>
    Http.get<IStockDB[]>(`stock/supplier/${supplier.id}`),

  fetchSupplier: (id: number): Promise<ISupplierDB> => Http.get<ISupplierDB>(`suppliers/${id}`),
  fetchSuppliers: (): Promise<ISupplierDB[]> => Http.get<ISupplierDB[]>("suppliers"),

  updateSupplier: (supplier: ISupplierDB, supplierUpdate: IUpdateSupplierForm): Promise<ISupplierDB> =>
    Http.post<ISupplierDB>("supplier", { id: supplier.id, values: supplierUpdate })
};
