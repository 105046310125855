import { useEffect, useState } from "react";
import { IStockDB } from "../shared/IStockDB";
import { ISupplierDB } from "../shared/ISupplierDB";
import Data from "../utils/Data";

interface ISupplierWithStock {
  stock: IStockDB[];
  supplier: ISupplierDB;
}

export default () => {
  const [suppliersWithstock, setSuppliersWithStock] = useState<ISupplierWithStock[]>([]);

  useEffect(() => {
    Data.fetchSuppliers().then(async (suppliers: ISupplierDB[]) => {
      const x = await Promise.all(
        suppliers.map(async supplier => {
          const stock = await Data.fetchStockBySupplier(supplier);
          return { supplier, stock };
        })
      );

      setSuppliersWithStock(x);
    });
  }, []);

  return suppliersWithstock;
};
