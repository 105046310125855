import { Button, Card, CardActions, CardContent, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import useGlobalStyles from "../../hooks/useGlobalStyles";

export default () => {
  const classes = useGlobalStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Breadcrumb crumbs={[{ name: "Admin", path: "/admin" }]} />
      </Grid>

      <Grid container item spacing={2}>
        <Grid item xs={3}>
          <Card className={classes.paper}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Categories
              </Typography>
              {/* <Typography>Shows the marketplace categories</Typography> */}
            </CardContent>
            <CardActions>
              <Button component={RouterLink} size="small" to="/admin/categories">
                Manage the categories
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card className={classes.paper}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Categories mappings
              </Typography>
              {/* <Typography>Shows the marketplace categories</Typography> */}
            </CardContent>
            <CardActions>
              <Button component={RouterLink} size="small" to="/admin/categories-mappings">
                Show the categories mappings
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
