import { useEffect, useState } from "react";
import { ICategoriesFilter } from "../shared/ICategoriesFilter";
import { ICategory } from "../shared/ICategory";
import Data from "../utils/Data";

export default (marketplace: string, enabledFilter: ICategoriesFilter): ICategory[] => {
  const [categories, setCategories] = useState<ICategory[]>([]);

  useEffect(() => {
    Data.fetchCategories(marketplace, enabledFilter).then(setCategories);
  }, [marketplace, enabledFilter]);

  return categories;
};
