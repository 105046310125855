import { createStyles, Grid, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import { Field, Form, Formik, FormikActions, FormikProps } from "formik";
import MaterialTable, { Column, MTableCell } from "material-table";
import { Select } from "material-ui-formik-components/Select";
import { TextField } from "material-ui-formik-components/TextField";
import React from "react";
import AutoCompleteAsync from "../../../components/AutoCompleteAsync";
import SaveButton from "../../../components/SaveButton";
import useAttributesForCategory from "../../../hooks/useAttributesForCategory";
import useAttributeValues from "../../../hooks/useAttributeValues";
import useBrands from "../../../hooks/useBrands";
import UpdateProductForm, { IUpdateProductForm } from "../../../shared/forms/UpdateProductForm";
import { IAttributeForCategory } from "../../../shared/IAttributeForCategory";
import { IProductRich } from "../../../shared/IProduct";

interface IProps {
  onSubmit: (values: IUpdateProductForm, formikActions: FormikActions<IUpdateProductForm>) => void;
  product: IProductRich;
}

const useStyles = makeStyles(() =>
  createStyles({
    td: {
      paddingBottom: 0,
      paddingRight: 0,
      paddingTop: 0
    }
  })
);

const Attributes = (props: { category: number }) => {
  const { category } = props;
  const attributes = useAttributesForCategory(category);
  const classes = useStyles();

  if (attributes.length === 0) {
    return <LinearProgress />;
  }

  const columns: Column[] = [
    { field: "marketplace", title: "marketplace" },
    { field: "label", title: "label" },
    { title: "Values", render: (row: IAttributeForCategory) => <Values attribute={row} /> }
  ];

  return (
    <MaterialTable
      columns={columns}
      components={{ Cell: (cellProps: any) => <MTableCell className={classes.td} {...cellProps} /> }}
      data={attributes}
      options={{ pageSize: attributes.length }}
      title="Attributes"
    />
  );
};

const Values = (props: { attribute: IAttributeForCategory }) => {
  const { attribute } = props;

  // TODO the attribute fetching
  const values = useAttributeValues(attribute.marketplace, attribute);

  if (values.length === 0) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Field
      component={Select}
      fullWidth
      name={`${attribute.marketplace}/${attribute.id}`}
      label={attribute.label}
      options={values.map(value => ({ value: value.marketplaceId, label: value.label }))}
      required={attribute.required}
      value={values[0].marketplaceId}
      style={{ width: "25em" }}
    />
  );
};

export default (props: IProps) => {
  const brands = useBrands();

  const { product } = props;

  const initialValues: IUpdateProductForm = {
    brandId: product.brand.id,
    categoryId: product.category.id,
    description: product.description,
    // images: product.images.length
    name: product.name
  };

  const brandsOptions = brands.map(brand => ({ label: brand.name, value: brand.id }));
  // const categoriesOptions = categories.map(category => ({ label: category.name, value: category.id }));

  return (
    <>
      <Typography component="h2" variant="h6">
        Product
      </Typography>

      <Formik initialValues={initialValues} onSubmit={props.onSubmit} validationSchema={UpdateProductForm.validator}>
        {(formikProps: FormikProps<IUpdateProductForm>) => (
          <Form>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                {/* <Field
                  required
                  name="categoryId"
                  label="Category"
                  options={categoriesOptions}
                  component={AutoCompleteAsync}
                /> */}
              </Grid>

              <Grid item>
                <Field required name="brandId" label="Brand" options={brandsOptions} component={AutoCompleteAsync} />
              </Grid>

              <Grid item>
                <Field component={TextField} label="Name" name="name" required />
              </Grid>

              <Grid item>
                <Field component={TextField} label="Description" multiline name="description" required />
              </Grid>

              {/* <Field component={TextField} label="Images" name="images" required type="file" /> */}

              <Grid item>
                <Attributes category={formikProps.values.categoryId} />
              </Grid>

              <Grid item>
                {JSON.stringify(formikProps)}
                <SaveButton
                  isSubmitting={formikProps.isSubmitting}
                  isValid={formikProps.isValid}
                  submitForm={formikProps.submitForm}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
