import React from "react";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

interface ISaveButtonProps {
  isSubmitting: boolean;
  isValid: boolean;
  submitForm: () => void;
}

export default (props: ISaveButtonProps) => {
  const onClick = () => props.submitForm();

  return (
    <Button color="primary" disabled={props.isSubmitting || !props.isValid} onClick={onClick} variant="contained">
      <SaveIcon /> Save
    </Button>
  );
};
