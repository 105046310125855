import { Button, CircularProgress, createStyles, Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { Field, Form, Formik, FormikActions, FormikProps } from "formik";
import { TextField } from "material-ui-formik-components/TextField";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router";
import { ActionLoggedIn } from "../../App/Store";
import LoginForm from "../../shared/forms/LoginForm";

interface IFormValues {
  email: string;
  password: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2)
    }
  })
);

const initialValues: IFormValues = {
  email: "",
  password: ""
};

export default (routeProps: RouteComponentProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const [hasCheckedAuth, setHasCheckedAuth] = useState(false);

  const { from } = routeProps.location.state || { from: { pathname: "/" } };

  console.log("[Login] render");

  if (isRedirecting) {
    console.log(`[Login] Redirecting to`, from);
    return <Redirect to={from} />;
  }

  if (!hasCheckedAuth) {
    console.log("[Login] Checking if there is an active session");
    setHasCheckedAuth(true);
    Auth.currentSession()
      .then(() => {
        console.log("[Login] Got an active session");
        dispatch({ type: ActionLoggedIn });
        setIsRedirecting(true);
      })
      .catch(e => {
        if (e === "No current user") {
          // No user, do nothing
          console.log("[Login] No active session user");
          return;
        }

        // Other exception, throw it
        throw e;
      });
  }

  const onSubmit = async (values: IFormValues, formikActions: FormikActions<IFormValues>) => {
    const { email, password } = values;

    try {
      console.log("[LoginPage] Will Login");
      console.log(await Auth.signIn(email, password));
      console.log("[LoginPage] Login successful");
      dispatch({ type: ActionLoggedIn });
      setIsRedirecting(true);
    } catch (e) {
      console.log("Can't login", e);
      formikActions.setSubmitting(false);
      // Invalid password
      // TOOD display the snackbar
      //   <Snackbar
      //     anchorOrigin={{
      //       vertical: 'bottom',
      //       horizontal: 'left',
      //     }}
      //     open={open}
      //     autoHideDuration={6000}
      //     onClose={handleClose}
      //     ContentProps={{
      //       'aria-describedby': 'message-id',
      //     }}
      //     message={<span id="message-id">Note archived</span>}
      //     action={[
      //       <Button key="undo" color="secondary" size="small" onClick={handleClose}>
      //         UNDO
      //       </Button>,
      //       <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={handleClose}
      //       >
      //         <CloseIcon />
      //       </IconButton>,
      //     ]}
      //   />
    }
  };

  return (
    <Grid container>
      <Grid item xs={4} />
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Typography align="center" variant="h6">
            Login to BrandAddict
          </Typography>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={LoginForm.validator}>
            {(formikProps: FormikProps<IFormValues>) => (
              <Form>
                {/* TOOD check that autofocus works */}
                <Field autoFocus component={TextField} label="Email" name="email" required />
                <Field component={TextField} label="Password" name="password" required type="password" />
                <Button
                  disabled={formikProps.isSubmitting || !formikProps.isValid}
                  onClick={formikProps.submitForm}
                  variant="contained"
                >
                  {formikProps.isSubmitting ? (
                    <CircularProgress style={{ height: "1rem", marginRight: ".5rem", width: "1rem" }} />
                  ) : (
                    ""
                  )}
                  Login
                </Button>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};
