import { Grid } from "@material-ui/core";
import { Field, Form, Formik, FormikActions, FormikProps } from "formik";
import { TextField } from "material-ui-formik-components/TextField";
import React from "react";
import SaveButton from "../../../components/SaveButton";
import UpdateSKUForm, { IUpdateSKUForm } from "../../../shared/forms/UpdateSKUForm";
import { ISkuRich } from "../../../shared/ISku";

interface IProps {
  sku: ISkuRich;
}

export default (props: IProps) => {
  const initialValues: IUpdateSKUForm = {
    ean: props.sku.ean,
    price: props.sku.price,
    reference: props.sku.reference
  };

  const onSubmit = (values: IUpdateSKUForm, formikActions: FormikActions<IUpdateSKUForm>) => {
    console.log(values);
    // TODO handle error
    formikActions.setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={UpdateSKUForm.validator}>
      {(formikProps: FormikProps<IUpdateSKUForm>) => {
        console.log("render component");
        return (
          <Form>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Field component={TextField} label="Refence" name="reference" />
              </Grid>

              <Grid item>
                {/* TODO check why maxlength doesn't work */}
                <Field component={TextField} label="EAN" maxLength="13" name="ean" />
              </Grid>

              <Grid item>
                <SaveButton
                  isSubmitting={formikProps.isSubmitting}
                  isValid={formikProps.isValid}
                  submitForm={formikProps.submitForm}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
