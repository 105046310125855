import { useEffect, useState } from "react";
import { IAttributeForCategory } from "../shared/IAttributeForCategory";
import { IAttributeValue } from "../shared/IAttributeValue";
import Data from "../utils/Data";

export default (marketplace: string, attribute: IAttributeForCategory): IAttributeValue[] => {
  const [attributeValues, setAttributeValues] = useState<IAttributeValue[]>([]);

  useEffect(() => {
    Data.attributes.getValues(marketplace, attribute.id).then(setAttributeValues);
  }, []);

  return attributeValues;
};
