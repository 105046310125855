import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { ActionLoggedOut } from "../../App/Store";

export default () => {
  const dispatch = useDispatch();
  const [isRedirecting, setIsRedirecting] = useState(false);

  console.log("[Logout] render");

  if (isRedirecting) {
    console.log("[Logout] Redirecting to /");
    return <Redirect to="/" />;
  }

  // Can't use async component with react router for now
  Auth.signOut().then(() => {
    console.log("[Logout] Logged-out");
    dispatch({ type: ActionLoggedOut });
    setIsRedirecting(true);
  });

  return <div>Logout</div>;
};
