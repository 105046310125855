import { useEffect, useState } from "react";
import { ICategoryMappingDB } from "../shared/ICategoryMappingDB";
import Data from "../utils/Data";

export default (): ICategoryMappingDB[] => {
  const [categoriesMappings, setCategoriesMappings] = useState<ICategoryMappingDB[]>([]);

  useEffect(() => {
    Data.fetchCategoriesMappings().then(setCategoriesMappings);
  }, []);

  return categoriesMappings;
};
