import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core";
import MaterialTable, { MTableCell } from "material-table";
import { RouteComponentProps, withRouter } from "react-router";
import { IStockDB } from "../../shared/IStockDB";

interface IProps extends RouteComponentProps {
  stock: IStockDB[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      height: theme.spacing(10)
    },
    td: {
      paddingBottom: 0,
      paddingRight: 0,
      paddingTop: 0
    }
  })
);

export default withRouter((props: IProps) => {
  const classes = useStyles();

  // TODO extract the path url
  const productsPath = "http://localhost:8000/brandaddict-app-dev/products";
  const getProductImage = (productId: number) => `${productsPath}/${productId}/0.jpg`;

  const tableProps = {
    actions: [
      {
        icon: "edit",
        onClick: (_: any, row: IStockDB) => props.history.push(`/products/${row.productId}/${row.skuId}`),
        tooltip: "Edit SKU"
      }
    ],

    columns: [
      {
        render: (rowData: IStockDB) => <img className={classes.img} src={getProductImage(rowData.productId)} />,
        title: "Image"
      },
      { field: "productBrandId", title: "Brand" },
      { field: "skuReference", title: "Reference" },
      { field: "productName", title: "Name" },
      { field: "quantity", title: "Quantity" },
      {
        render: (rowData: IStockDB) => new Date(rowData.updated).toLocaleString(),
        title: "Last stock update"
      }
    ],

    components: { Cell: (cellProps: any) => <MTableCell className={classes.td} {...cellProps} /> },
    data: props.stock
  };

  return <MaterialTable {...tableProps} title="SKUs" />;
});
