import { Grid, LinearProgress } from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import React from "react";
import { RouteComponentProps } from "react-router";
import Breadcrumb from "../../components/Breadcrumb";
import useSuppliersWithStock from "../../hooks/useSuppliersWithStock";
import { ISupplierDB } from "../../shared/ISupplierDB";

interface IData {
  skusCount: number;
  stockQuantity: number;
  supplier: ISupplierDB;
}

export default (props: RouteComponentProps) => {
  const suppliersWithStock = useSuppliersWithStock();

  if (suppliersWithStock.length === 0) {
    return <LinearProgress />;
  }

  const data: IData[] = suppliersWithStock.map(sws => ({
    skusCount: sws.stock.length,
    stockQuantity: sws.stock.reduce((acc, stock) => acc + stock.quantity, 0),
    supplier: sws.supplier
  }));

  const actions = [
    {
      icon: "edit",
      onClick: (_: any, row: IData) => props.history.push(`/suppliers/${row.supplier.id}`),
      tooltip: "Edit supplier"
    }
  ];

  const columns: Column[] = [
    { title: "Name", field: "supplier.name" },
    { title: "SKUs", field: "skusCount" },
    { title: "Stock", field: "stockQuantity" },
    {
      render: (row: IData) => new Date(row.supplier.created).toLocaleDateString(),
      title: "Joined on"
    }
  ];

  return (
    <Grid container={true} direction="column" spacing={2}>
      <Grid item={true}>
        <Breadcrumb crumbs={[{ name: "Suppliers", path: "/suppliers" }]} />
      </Grid>
      <Grid item={true}>
        <MaterialTable actions={actions} columns={columns} data={data} title="Suppliers" />
      </Grid>
    </Grid>
  );
};
