import { useEffect, useState } from "react";
import { IAttributeForCategory } from "../shared/IAttributeForCategory";
import Data from "../utils/Data";

export default (categoryId: number): IAttributeForCategory[] => {
  const [attributes, setAttributes] = useState<IAttributeForCategory[]>([]);

  useEffect(() => {
    Data.attributes.getForCategory(categoryId).then(setAttributes);
  }, [categoryId]);

  return attributes;
};
