import { useEffect, useState } from "react";
import { IBrand } from "../shared/IBrand";
import Data from "../utils/Data";

export default (): IBrand[] => {
  const [brands, setBrands] = useState<IBrand[]>([]);

  useEffect(() => {
    Data.fetchBrands().then(setBrands);
  }, []);

  return brands;
};
