import { createStyles, Grid, LinearProgress, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { FormikActions } from "formik";
import MaterialTable from "material-table";
import React from "react";
import { RouteComponentProps } from "react-router";
import Breadcrumb from "../../../components/Breadcrumb";
import useProduct from "../../../hooks/useProduct";
import { IUpdateProductForm } from "../../../shared/forms/UpdateProductForm";
import { ISkuRich } from "../../../shared/ISku";
import EditProductForm from "./EditProductForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2)
    }
  })
);

// TODO extract
//
// // TODO extract
// const useStockForSKU = (sku: ISkuDB): IStockDB[] => {
//   const [stock, setStock] = useState<IStockDB[]>([]);
//
//   useEffect(() => {
//     Data.fetchStockBySKU(sku).then(setStock);
//   }, []);
//
// //   return stock;
// };

interface IParams {
  productId: string;
  skuId: string | undefined;
}

const onSubmit = (values: IUpdateProductForm, formikActions: FormikActions<IUpdateProductForm>) => {
  console.log("onSubmit from edit product page");
  console.log(formikActions);
  console.log(values);
  // TODO handle error
  formikActions.setSubmitting(false);
};

export default (props: RouteComponentProps<IParams>) => {
  const productId = Number(props.match.params.productId);

  const productWithSkus = useProduct(productId);
  const classes = useStyles();

  if (!productWithSkus) {
    return <LinearProgress />;
  }

  const { product, skus } = productWithSkus;

  const onSKUClick = (_: any, sku: ISkuRich) => {
    console.log(sku);
    props.history.push(`/products/${sku.product.id}/${sku.id}`);
  };

  const renderDetailPanel = () => <Typography>Detail panel</Typography>;

  // TODO Move attributes at the product level
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Breadcrumb crumbs={[{ name: `Product ${product.name}`, path: `/products/${productId}` }]} />
      </Grid>

      <Grid item>
        <Paper className={classes.paper}>
          <EditProductForm onSubmit={onSubmit} product={product} />
        </Paper>
      </Grid>

      <Grid item>
        <MaterialTable
          actions={[
            {
              icon: "edit",
              onClick: onSKUClick,
              tooltip: "Edit SKU"
            }
          ]}
          columns={[{ field: "ean", title: "EAN" }, { field: "reference", title: "Reference" }]}
          data={skus}
          detailPanel={renderDetailPanel}
          title="SKUs"
        />
      </Grid>
    </Grid>
  );
};
