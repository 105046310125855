import { AppBar, Button, createStyles, IconButton, makeStyles, Theme, Toolbar, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import Store, { IAppProps } from "./Store";

const useMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    }
  })
);

interface IProps extends RouteComponentProps, IAppProps {
  entries: IMenuEntry[];
}

interface IMenuEntry {
  label: string;
  path: string;
}

const getPrivateRoutes = (entries: IMenuEntry[], isAuthenticated: boolean) => {
  if (!isAuthenticated) {
    return null;
  }

  return entries.map(entry => (
    <Button key={entry.label} component={RouterLink} color="inherit" to={entry.path}>
      {entry.label}
    </Button>
    // <IconButton color="inherit">
    //   <AccountCircleIcon />
    // </IconButton>
  ));
};

export default connect(Store.mapAuthStateToProps)(
  withRouter((props: IProps) => {
    const classes = useMenuStyles();
    const { entries, isAuthenticated } = props;

    const onDashboardClick = () => props.history.push("/");

    const privateRoute = getPrivateRoutes(entries, isAuthenticated);

    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu">
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} onClick={onDashboardClick} variant="h6">
            BrandAddict
          </Typography>
          {privateRoute}
        </Toolbar>
      </AppBar>
    );
  })
);
