import { createStyles, Grid, LinearProgress, makeStyles, Paper, Theme } from "@material-ui/core";
import { FormikActions } from "formik";
import MaterialTable from "material-table";
import React from "react";
import { RouteComponentProps } from "react-router";
import Breadcrumb from "../../../components/Breadcrumb";
import useSKU from "../../../hooks/useSKU";
import { IUpdateProductForm } from "../../../shared/forms/UpdateProductForm";
import EditProductForm from "./EditProductForm";
import EditSKUForm from "./EditSKUForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2)
    }
  })
);

interface IParams {
  productId: string;
  skuId: string | undefined;
}

const onSubmit = (values: IUpdateProductForm, formikActions: FormikActions<IUpdateProductForm>) => {
  console.log("onSubmit product from edit SKU page", formikActions, values);
  // TODO handle error
  formikActions.setSubmitting(false);
};

export default (props: RouteComponentProps<IParams>) => {
  const skuId = Number(props.match.params.skuId);

  const classes = useStyles();
  const sku = useSKU(skuId);

  if (!sku) {
    return <LinearProgress />;
  }

  const onSupplierClick = () => {
    console.log("click");
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Breadcrumb
          crumbs={[
            { name: `Product ${sku.product.name}`, path: `/products/${sku.product.id}` },
            {
              name: `Edit SKU ${sku.reference}`,
              path: `/products/${sku.product.id}/${sku.id}`
            }
          ]}
        />
      </Grid>

      <Grid item>
        <Paper className={classes.paper}>
          <EditProductForm onSubmit={onSubmit} product={sku.product} />
        </Paper>
      </Grid>

      <Grid item>
        <Paper className={classes.paper} title="xx">
          <EditSKUForm sku={sku} />
        </Paper>
      </Grid>

      <Grid item>
        <MaterialTable
          actions={[{ icon: "edit", onClick: onSupplierClick, tooltip: "Edit" }]}
          columns={[{ field: "supplier.name", title: "Supplier" }, { field: "stockQuantity", title: "Stock" }]}
          data={[
            { stockQuantity: 100, supplier: { id: 1, name: "FAKE Supplier 1" } },
            { stockQuantity: 200, supplier: { id: 2, name: "FAKE Supplier 2" } }
          ]}
          title="Stock"
        />
      </Grid>
    </Grid>
  );
};
