import * as yup from "yup";
import { ISkuRich } from "../ISku";

export type IUpdateSKUForm = Pick<ISkuRich, "ean" | "price" | "reference">;

// TODO move the schemas to IForms
export default {
  validator: yup.object().shape({
    ean: yup
      .string()
      .trim()
      .matches(/^\d{13}$/, "Invalid EAN")
      .required("Required"),

    price: yup
      .number()
      .positive()
      .lessThan(99999.99), // defined as max 5.2 digits in the db

    reference: yup
      .string()
      .trim()
      .min(3)
      .required("Required")
  })
};
