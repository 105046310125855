const get = (key: string): string => {
  const envKey = `REACT_APP_${key}`;
  const value = process.env[envKey];

  if (!value) {
    throw new Error(`Env variablt ${envKey} not found`);
  }
  return value;
};

export default {
  apiPath: get("API_PATH"),
  aws: {
    cognito: {
      appClientId: get("AWS_COGNITO_APP_CLIENT_ID"),
      identityPoolId: get("AWS_COGNITO_IDENTITY_POOL_ID"),
      userPoolId: get("AWS_COGNITO_USER_POOL_ID")
    },
    endpointName: get("AWS_ENDPOINT_NAME"),
    region: get("AWS_REGION"),
    s3Bucket: get("AWS_S3_BUCKET")
  }
};
