import { Grid } from "@material-ui/core";
import MaterialTable, { Action } from "material-table";
import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import useCategoriesMappings from "../../hooks/useCategoriesMappings";
import { ICategoryMappingDB } from "../../shared/ICategoryMappingDB";

export default () => {
  const categories = useCategoriesMappings();

  // const onActionClick = (_: any, rowData: IRow) => routeProps.history.push(`/categories/${rowData.id}`);
  // const actions: Action[] = [{ icon: "edit", onClick: onActionClick }];
  const actions: Action[] = [];

  const columns = [
    { field: "id", title: "ID" },
    { field: "filters", title: "Filters", render: (row: ICategoryMappingDB) => row.filters.join(" > ") },
    { field: "amazonId", title: "Amazon", render: (row: ICategoryMappingDB) => `${row.amazonLabel} (${row.amazonId})` },
    {
      field: "amazonBrowseNodeId",
      render: (row: ICategoryMappingDB) => `${row.amazonBrowseNodeLabel} (${row.amazonBrowseNodeId})`,
      title: "Amazon Browse Node"
    },
    {
      field: "cdiscountId",
      render: (row: ICategoryMappingDB) => `${row.cdiscountLabel} (${row.cdiscountId})`,
      title: "Cdiscount"
    },
    {
      field: "laRedouteId",
      render: (row: ICategoryMappingDB) => `${row.laRedouteLabel} (${row.laRedouteId})`,
      title: "La Redoute"
    }
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Breadcrumb
          crumbs={[
            { name: "Admin", path: "/admin" },
            { name: "Categories mappings", path: "/admin/categories-mappings" }
          ]}
        />
      </Grid>

      <Grid item>
        <MaterialTable
          actions={actions}
          columns={columns}
          data={categories}
          options={{ exportAllData: true, exportButton: true, filtering: true }}
          title="Categories mappings"
        />
      </Grid>
    </Grid>
  );
};
