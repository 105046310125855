import { useEffect, useState } from "react";
import { ISkuRich } from "../shared/ISku";
import Data from "../utils/Data";

export default (productId: number) => {
  const [skus, setSkus] = useState<ISkuRich[]>();

  useEffect(() => {
    Data.fetchProduct(productId).then(setSkus);
  }, []);

  if (!skus) {
    return null;
  }

  return { product: skus[0].product, skus };
};
