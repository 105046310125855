import { Grid, Paper, Typography } from "@material-ui/core";
import { distanceInWords } from "date-fns";
import { Field, Form, Formik, FormikActions, FormikProps } from "formik";
import { TextField } from "material-ui-formik-components/TextField";
import React from "react";
import SaveButton from "../../components/SaveButton";
import useGlobalStyles from "../../hooks/useGlobalStyles";
import supplierSchema, { IUpdateSupplierForm } from "../../shared/forms/UpdateSupplierForm";
import { ISupplierDB } from "../../shared/ISupplierDB";

export default (props: {
  onSubmit: (values: IUpdateSupplierForm, formikActions: FormikActions<IUpdateSupplierForm>) => void;
  supplier: ISupplierDB;
}) => {
  const classes = useGlobalStyles();

  const { supplier } = props;

  const initialValues: IUpdateSupplierForm = {
    email: supplier.email,
    name: supplier.name
  };

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={8}>
          <Formik initialValues={initialValues} onSubmit={props.onSubmit} validationSchema={supplierSchema}>
            {(formikProps: FormikProps<IUpdateSupplierForm>) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Field component={TextField} label="Name" name="name" />
                  </Grid>

                  <Grid item xs={8}>
                    <Field component={TextField} label="Email" name="email" type="email" />
                  </Grid>

                  <Grid item xs={12}>
                    <SaveButton
                      isSubmitting={formikProps.isSubmitting}
                      isValid={formikProps.isValid}
                      submitForm={formikProps.submitForm}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            Created {distanceInWords(new Date(), new Date(supplier.created), { addSuffix: true })}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
