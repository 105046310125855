import { createStore } from "redux";

interface IState {
  isAuthenticated: boolean;
}

export type IAppProps = IState;

export const ActionLoggedIn = "LOGGED_IN";
export const ActionLoggedOut = "LOGGED_OUT";

export interface IAction {
  type: typeof ActionLoggedIn | typeof ActionLoggedOut;
}

const initialState: IState = {
  isAuthenticated: false
};

const reducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case ActionLoggedIn:
      return { ...state, isAuthenticated: true };
    case ActionLoggedOut:
      return { ...state, isAuthenticated: false };
    default:
      return state;
  }
};

export default {
  mapAuthStateToProps: (state: IState): IAppProps => {
    return {
      isAuthenticated: state.isAuthenticated
    };
  },
  store: createStore(reducer)
};
