import { createStyles, Grid, LinearProgress, makeStyles, Paper, Theme } from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import React from "react";
import { RouteComponentProps } from "react-router";
import Breadcrumb from "../../components/Breadcrumb";
import useAttributesForCategory from "../../hooks/useAttributesForCategory";
import useCategory from "../../hooks/useCategory";

interface IRow {
  marketplace: string;
  id: number;
  label: string;
  required: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2)
    }
  })
);

interface IRouteProps {
  id: string;
}

export default (routeProps: RouteComponentProps<IRouteProps>) => {
  const id = Number(routeProps.match.params.id);

  const classes = useStyles();
  const category = useCategory(id);
  const attributes = useAttributesForCategory(id);

  // console.log("[EditCategoryPage]", { param: routeProps.match.params, id, category, attributes });

  if (!category || attributes.length === 0) {
    return <LinearProgress />;
  }

  const columns: Column[] = [
    { field: "id", title: "ID" },
    { field: "marketplace", title: "Marketplace" },
    { field: "label", title: "Label" },
    { field: "required", title: "Required", render: (row: IRow) => (row.required ? "required" : "-") }
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Breadcrumb
          crumbs={[{ name: "Categories", path: "/categories" }, { name: "XXXXX", path: `/categories/${category.id}` }]}
        />
      </Grid>

      <Grid item>
        <Paper className={classes.paper}>
          <MaterialTable columns={columns} data={attributes} options={{ pageSize: attributes.length }} title={"xxx"} />
        </Paper>
      </Grid>
    </Grid>
  );
};
