import { Button, Grid, LinearProgress, Paper, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { FormikActions } from "formik";
import React from "react";
import { RouteComponentProps } from "react-router";
import Breadcrumb from "../../components/Breadcrumb";
import useGlobalStyles from "../../hooks/useGlobalStyles";
import useSupplierWithStock from "../../hooks/useSupplierWithStock";
import { IUpdateSupplierForm } from "../../shared/forms/UpdateSupplierForm";
import Data from "../../utils/Data";
import EditSupplierForm from "./EditSupplierForm";
import SKUsForSupplierTable from "./SKUsForSupplierTable";

interface IParams {
  id: string;
}

export default (routeProps: RouteComponentProps<IParams>) => {
  const classes = useGlobalStyles();
  const supplierWithStock = useSupplierWithStock(Number(routeProps.match.params.id));

  if (!supplierWithStock) {
    return <LinearProgress />;
  }

  const { stock, supplier } = supplierWithStock;

  const onAddProductClick = () => {
    routeProps.history.push(`/products/new?supplier=${supplier.id}`);
  };

  const onDownloadExcelModelClicked = () => {
    console.log("click");
  };

  const onFormSubmit = async (values: IUpdateSupplierForm, formikActions: FormikActions<IUpdateSupplierForm>) => {
    await Data.updateSupplier(supplier, values);
    // TODO manage form update error
    formikActions.setSubmitting(false);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Breadcrumb
          crumbs={[
            { name: "Suppliers", path: "/suppliers" },
            { name: supplier.name, path: `/suppliers/${supplier.id}` }
          ]}
        />
      </Grid>

      <Grid item>
        <EditSupplierForm onSubmit={onFormSubmit} supplier={supplier} />
      </Grid>

      <Grid item>
        <Paper className={classes.paper}>
          <Typography>Upload inventory</Typography>

          <div>
            <Button onClick={onDownloadExcelModelClicked} variant="contained">
              Download the Excel model
            </Button>
          </div>

          <div>
            <input type="file" />
          </div>
        </Paper>
      </Grid>

      <Grid item>
        <Paper>
          <SKUsForSupplierTable stock={stock} />

          <Button color="primary" onClick={onAddProductClick}>
            <AddIcon /> New Product
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
};
