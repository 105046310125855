import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";
import amplify from "aws-amplify";
import React from "react";
import { connect, Provider } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import AdminPage from "../pages/admin/AdminPage";
import AdminVersionPage from "../pages/admin/AdminVersionPage";
import ListCategoriesMappingsPage from "../pages/admin/ListCategoriesMappingsPage";
import ListCategoriesPage from "../pages/admin/ListCategoriesPage";
import LoginPage from "../pages/auth/LoginPage";
import LogoutPage from "../pages/auth/LogoutPage";
import EditCategoryPage from "../pages/categories/EditCategoryPage";
import DashboardPage from "../pages/DashboardPage";
import Page404 from "../pages/Page404";
import CreateProductPage from "../pages/products/CreateProductPage";
import EditProductPage from "../pages/products/edit/EditProductPage";
import EditSKUPage from "../pages/products/edit/EditSKUPage";
import EditSupplierPage from "../pages/suppliers/EditSupplierPage";
import ListSuppliersPage from "../pages/suppliers/ListSuppliersPage";
import Config from "../utils/Config";
import Menu from "./Menu";
import Store, { IAppProps } from "./Store";

const PrivateRouteComponent = (props: any & IAppProps) => {
  const { component, isAuthenticated, ...rest } = props;

  if (isAuthenticated) {
    const routeComponent = (routeComponentProps: any) => React.createElement(component, routeComponentProps);
    return <Route {...rest} render={routeComponent} />;
  }

  console.log(`[PrivateRoute] Not authenticated, redirect to /login`);
  return <Redirect to={{ pathname: "/login", state: { from: rest.location } }} />;
};

const PrivateRoute = connect(Store.mapAuthStateToProps)(PrivateRouteComponent);

const useAppStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(10)
    },
    root: {
      backgroundColor: theme.palette.grey[200],
      minHeight: "100%"
    }
  })
);

export default () => {
  const classes = useAppStyles();

  amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: Config.aws.region,
      userPoolId: Config.aws.cognito.userPoolId,
      identityPoolId: Config.aws.cognito.identityPoolId,
      userPoolWebClientId: Config.aws.cognito.appClientId
    },
    Storage: {
      region: Config.aws.region,
      bucket: Config.aws.s3Bucket,
      identityPoolId: Config.aws.cognito.identityPoolId
    },
    API: {
      endpoints: [
        {
          name: Config.aws.endpointName,
          endpoint: Config.apiPath,
          region: Config.aws.region
        }
      ]
    }
  });

  console.log("render App");

  return (
    <Provider store={Store.store}>
      <Router>
        <div className={classes.root}>
          <Menu
            entries={[
              { label: "Admin", path: "/admin" },
              { label: "Suppliers", path: "/suppliers" },
              { label: "Logout", path: "/logout" }
            ]}
          />
          <Container className={classes.container} maxWidth={false}>
            <Switch>
              {/* Public routes */}
              <Route path="/login" component={LoginPage} exact />
              <Route path="/logout" component={LogoutPage} exact />
              {/* Private routes */}
              <PrivateRoute path="/" component={DashboardPage} exact />
              <PrivateRoute path="/admin" component={AdminPage} exact />
              <PrivateRoute path="/admin/version" component={AdminVersionPage} exact />
              <PrivateRoute path="/admin/categories" component={ListCategoriesPage} exact />
              <PrivateRoute path="/admin/categories-mappings" component={ListCategoriesMappingsPage} exact />
              <PrivateRoute path="/categories/:id" component={EditCategoryPage} />
              <PrivateRoute path="/products/new" component={CreateProductPage} exact />
              <PrivateRoute path="/products/:productId" component={EditProductPage} exact />
              <PrivateRoute path="/products/:productId/:skuId" component={EditSKUPage} />
              <PrivateRoute path="/suppliers" component={ListSuppliersPage} exact />
              <PrivateRoute path="/suppliers/:id" component={EditSupplierPage} />
              {/* Must be last to catch all the routes */}
              <Route component={Page404} />
            </Switch>
          </Container>
        </div>
      </Router>
    </Provider>
  );
};
