import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        padding: theme.spacing(2)
      }
    })
  );

  return useStyles();
};
