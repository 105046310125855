import { Breadcrumbs, createStyles, Link, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    }
  })
);

interface ICrumb {
  name: string;
  path: string;
}

interface IProps {
  crumbs: ICrumb[];
}

export default (props: IProps) => {
  const classes = useStyles();

  const crumbs = [{ name: "Dashboard", path: "/" }, ...props.crumbs];

  const allButLast = crumbs.slice(0, crumbs.length - 1);
  const last = crumbs.slice(-1)[0];

  return (
    <Paper className={classes.root}>
      <Breadcrumbs aria-label="Breadcrumb">
        {allButLast.map(crumb => (
          <Link color="inherit" component={RouterLink} to={crumb.path} key={crumb.path}>
            {crumb.name}
          </Link>
        ))}
        <Typography color="textPrimary">{last.name}</Typography>
      </Breadcrumbs>
    </Paper>
  );
};
