import * as yup from "yup";

export default {
  validator: yup.object().shape({
    email: yup
      .string()
      .trim()
      .email("Invalid email")
      .required("Required"),

    password: yup
      .string()
      .trim()
      .min(3)
      .required("Required")
  })
};
