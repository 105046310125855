import * as yup from "yup";
import { IProductDB } from "../IProduct";

export type IUpdateProductForm = Pick<IProductDB, "brandId" | "categoryId" | "description" | "name">;

export default {
  validator: yup.object().shape({
    categoryId: yup.number().positive(),
    description: yup
      .string()
      .trim()
      .min(10)
      .required("Required"),
    name: yup
      .string()
      .trim()
      .min(3)
      .required("Required")
  })
};
