import { Button, Grid, Paper } from "@material-ui/core";
import MaterialTable, { Action } from "material-table";
import React, { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import useCategories from "../../hooks/useCategories";
import useGlobalStyles from "../../hooks/useGlobalStyles";
import { ICategoriesFilter } from "../../shared/ICategoriesFilter";
import { ICategory } from "../../shared/ICategory";

// const useAPIEndpoint = (path: string): string => {
//   const [endpoint, setEndpoint] = useState("");

//   useEffect(() => {
//     API.endpoint(Config.aws.endpointName).then(setEndpoint);
//   });

//   return `${endpoint}${path}`;
// };

export default () => {
  const classes = useGlobalStyles();
  const [marketplaceFilter, setMarketplaceFilter] = useState("amazon");
  const [enabledFilter, setEnabledFilter] = useState<ICategoriesFilter>("enabled");
  const categories = useCategories(marketplaceFilter, enabledFilter);

  const onMarketplaceAmazonClick = () => setMarketplaceFilter("amazon");
  const onMarketplaceAmazonBrowseNodesClick = () => setMarketplaceFilter("amazonBrowseNodes");
  const onMarketplaceCdiscountClick = () => setMarketplaceFilter("cdiscount");
  const onMarketplaceLaRedouteClick = () => setMarketplaceFilter("laRedoute");

  const onEnabledFilterClick = () => setEnabledFilter("enabled");
  const onDisabledFilterClick = () => setEnabledFilter("disabled");
  const onAllFilterClick = () => setEnabledFilter("all");

  // const onActionClick = (_: any, rowData: IRow) => routeProps.history.push(`/categories/${rowData.id}`);
  // const actions: Action[] = [{ icon: "edit", onClick: onActionClick }];
  const actions: Action[] = [];

  const columns = [
    { field: "id", title: "ID" },
    { field: "marketplaceId", title: "Marketplace ID" },
    { field: "label", title: "Label" },
    { field: "enabled", title: "Enabled", render: (row: ICategory) => `${row.enabled ? "enabled" : "disabled"}` }
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Breadcrumb crumbs={[{ name: "Admin", path: "/admin" }, { name: "Categories", path: "/admin/categories" }]} />
      </Grid>

      <Grid item container spacing={2}>
        <Grid item xs>
          <Paper className={classes.paper}>
            <Button disabled={marketplaceFilter === "amazon"} onClick={onMarketplaceAmazonClick}>
              Amazon
            </Button>
            <Button disabled={marketplaceFilter === "amazonBrowseNodes"} onClick={onMarketplaceAmazonBrowseNodesClick}>
              Amazon Browse Nodes
            </Button>
            <Button disabled={marketplaceFilter === "cdiscount"} onClick={onMarketplaceCdiscountClick}>
              Cdiscount
            </Button>
            <Button disabled={marketplaceFilter === "laRedoute"} onClick={onMarketplaceLaRedouteClick}>
              La Redoute
            </Button>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.paper}>
            <Button disabled={enabledFilter === "enabled"} onClick={onEnabledFilterClick}>
              Show only enabled
            </Button>
            <Button disabled={enabledFilter === "disabled"} onClick={onDisabledFilterClick}>
              Show only disabled
            </Button>
            <Button disabled={enabledFilter === "all"} onClick={onAllFilterClick}>
              Show all
            </Button>
          </Paper>
        </Grid>
      </Grid>

      <Grid item>
        <MaterialTable
          actions={actions}
          columns={columns}
          data={categories}
          options={{ exportAllData: true, exportButton: true, filtering: true }}
          title={`${marketplaceFilter} ${enabledFilter} categories`}
        />
      </Grid>
    </Grid>
  );
};
