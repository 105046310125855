import { useEffect, useState } from "react";
import { ISkuRich } from "../shared/ISku";
import Data from "../utils/Data";

export default (id: number): ISkuRich | undefined => {
  const [sku, setSKU] = useState<ISkuRich>();

  useEffect(() => {
    Data.fetchSKU(id).then(setSKU);
  }, []);

  return sku;
};
