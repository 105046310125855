import { Button, Card, CardActions, CardContent, Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     card: {
//       backgroundColor: theme.palette.grey
//     }
//   })
// );

export default () => {
  // const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Suppliers
            </Typography>
            <ul>
              <Typography component="li">
                <Link component={RouterLink} to="/suppliers/1">
                  Supplier 1
                </Link>
              </Typography>
            </ul>
          </CardContent>
          <CardActions>
            <Button component={RouterLink} size="small" to="/suppliers">
              Show all
            </Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={4}>
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Updates
            </Typography>
            <ul>
              <Typography component="li">Add the price field to the skus table</Typography>
              <Typography component="li">Add the images to the skus table</Typography>
            </ul>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
