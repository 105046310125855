import { useEffect, useState } from "react";
import { ICategoryMappingDB } from "../shared/ICategoryMappingDB";
import Data from "../utils/Data";

export default (id: number): ICategoryMappingDB | undefined => {
  const [category, setCategory] = useState<ICategoryMappingDB>();

  useEffect(() => {
    Data.fetchCategory(id).then(setCategory);
  }, []);

  return category;
};
