import * as yup from "yup";
import { ISupplierDB } from "../ISupplierDB";

export type IUpdateSupplierForm = Pick<ISupplierDB, "email" | "name">;

export default {
  validator: yup.object().shape({
    email: yup
      .string()
      .trim()
      .email("Invalid email")
      .required("Required"),
    name: yup
      .string()
      .trim()
      .min(3)
      .required("Required")
  })
};
